import React from 'react';
import { Script } from 'gatsby';

export const wrapPageElement = ({ element }) => {
    return (
        <>
            {element}
            <Script id="usercentrics-cmp" async data-eu-mode="true" data-settings-id="dHFGMe5rKiqx9u"
                src="https://app.eu.usercentrics.eu/browser-ui/latest/loader.js"></Script>
            <Script type="application/javascript" src="https://sdp.eu.usercentrics.eu/latest/uc-block.bundle.js"></Script>

        </>
    )
}