exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-booking-js": () => import("./../../../src/pages/booking.js" /* webpackChunkName: "component---src-pages-booking-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-templates-blog-template-js": () => import("./../../../src/templates/blog-template.js" /* webpackChunkName: "component---src-templates-blog-template-js" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-post-01-mdx": () => import("./../../../src/templates/post-template.js?__contentFilePath=/codebuild/output/src1115738408/src/berlin-wing-chun/src/posts/post-01.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-post-01-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-post-02-mdx": () => import("./../../../src/templates/post-template.js?__contentFilePath=/codebuild/output/src1115738408/src/berlin-wing-chun/src/posts/post-02.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-post-02-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-post-03-mdx": () => import("./../../../src/templates/post-template.js?__contentFilePath=/codebuild/output/src1115738408/src/berlin-wing-chun/src/posts/post-03.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-post-03-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-post-04-mdx": () => import("./../../../src/templates/post-template.js?__contentFilePath=/codebuild/output/src1115738408/src/berlin-wing-chun/src/posts/post-04.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-post-04-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-post-05-mdx": () => import("./../../../src/templates/post-template.js?__contentFilePath=/codebuild/output/src1115738408/src/berlin-wing-chun/src/posts/post-05.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-post-05-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-post-06-mdx": () => import("./../../../src/templates/post-template.js?__contentFilePath=/codebuild/output/src1115738408/src/berlin-wing-chun/src/posts/post-06.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-post-06-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-post-07-mdx": () => import("./../../../src/templates/post-template.js?__contentFilePath=/codebuild/output/src1115738408/src/berlin-wing-chun/src/posts/post-07.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-post-07-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-post-08-mdx": () => import("./../../../src/templates/post-template.js?__contentFilePath=/codebuild/output/src1115738408/src/berlin-wing-chun/src/posts/post-08.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-post-08-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-post-09-mdx": () => import("./../../../src/templates/post-template.js?__contentFilePath=/codebuild/output/src1115738408/src/berlin-wing-chun/src/posts/post-09.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-post-09-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-post-10-mdx": () => import("./../../../src/templates/post-template.js?__contentFilePath=/codebuild/output/src1115738408/src/berlin-wing-chun/src/posts/post-10.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-post-10-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-post-11-mdx": () => import("./../../../src/templates/post-template.js?__contentFilePath=/codebuild/output/src1115738408/src/berlin-wing-chun/src/posts/post-11.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-post-11-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-post-12-mdx": () => import("./../../../src/templates/post-template.js?__contentFilePath=/codebuild/output/src1115738408/src/berlin-wing-chun/src/posts/post-12.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-post-12-mdx" */)
}

